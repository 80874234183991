<template>
  <div class="home">
    <v-row>
      <v-btn
        color="transparent"
        small
        @click="backOrderArhive"
        elevation="0"
        class="mb-2"
      >
        <v-icon small left>mdi-arrow-left</v-icon>
        {{ $t("buttons.back") }}
      </v-btn>
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <h2 class="main-title">{{ $t("new.order") }} № {{ orderId }}</h2>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col lg="4" md="12">
        <v-btn
          :class="{ active: activeButton === 'order' }"
          class="btn__task"
          width="100%"
          @click="buttonOrder"
          >{{ $t("buttons.orderCard") }}</v-btn
        >
      </v-col>
      <v-col lg="4" md="12">
        <v-btn
          width="100%"
          class="btn__task"
          :class="{ active: activeButton === 'task' }"
          @click="buttonTask"
          >{{ $t("buttons.taskTransportation") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="orderCard">
      <v-col lg="8" md="12">
        <div class="order-col">
          <v-text-field
            class="order-field pa-0"
            v-for="header in ORDERFIELDS"
            :key="header.value"
            :label="header.text"
            :value="values(header.value)"
            disabled
          />
        </div>
        <v-textarea
          :value="ORDER.notation"
          background-color="#F1F3F9"
          rows="3"
          no-resize
          filled
          disabled
          class="order-textarea"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row v-if="task">
      <v-col cols="12">
        <table-viewing
          :headers="headers"
          :users_data="TASKSORDER"
          :counts="COUNT"
          :hide="true"
          @onPagination="
            getFilterData(
              objectProfile,
              objectVehicle,
              sortOptions,
              ...arguments
            )
          "
          @sortBy="
            getFilterData(objectProfile, objectVehicle, ...arguments, page)
          "
          :page="page"
          :load="load"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tableViewing from "@/components/TableViewing";

export default {
  name: "",
  components: { tableViewing },
  data: vm => {
    return {
      page: 1,
      objectProfile: undefined,
      objectVehicle: undefined,
      sortOptions: { desc: [false], ordering_field: ["id"] },
      newItem: {},
      isValid: false,
      orderCard: true,
      task: false,
      orderId: "",
      activeButton: "order",
      load: true,

      headers: [
        { text: vm.$t("table.taskId"), value: "id", visible: true },
        { text: vm.$t("table.order"), value: "order", visible: true },
        { text: vm.$t("table.created"), value: "creator", visible: true },
        {
          text: vm.$t("table.driver"),
          value: "driver",
          visible: true
        },
        {
          text: vm.$t("table.creationTime"),
          value: "created_dt",
          visible: true
        },
        {
          text: vm.$t("table.vehicle"),
          value: "vehicle",
          visible: true
        },
        {
          text: vm.$t("table.factEnding"),
          value: "finish_fact_dt",
          visible: true
        }
      ]
    };
  },

  props: {
    users_data: {},
    title: {}
  },
  computed: {
    ...mapGetters(["ORDER", "ORDERFIELDS", "TASKFIELDS", "TASKSORDER", "COUNT"])
  },

  methods: {
    ...mapActions(["getOrderByID", "getTasksOrder"]),
    backOrderArhive() {
      this.$router.push({ name: "archive" });
    },
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = this.sortOptions,
      page = this.page
    ) {
      console.log(sort);
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "?ordering=-id";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.getTasksOrder({
        id: this.$route.params.userId + `/` + arg
      });
    },
    buttonOrder() {
      this.activeButton = "order";
      this.task = false;
      this.orderCard = true;
    },
    buttonTask() {
      this.activeButton = "task";
      this.orderCard = false;
      this.task = true;
      this.load = false;
    },
    values(index) {
      if (this.ORDER[index] !== -1) {
        if (typeof this.ORDER[index] === "object") {
          if (!this.ORDER[index].name) return this.ORDER[index].email;
          else return this.ORDER[index].name;
        }
        if (this.ORDER[index] === false) {
          this.ORDER[index] = "inActive";
        } else return this.ORDER[index];
      }
    }
  },
  mounted() {
    this.orderId = this.$route.params.userId;
    this.getOrderByID({ id: this.orderId });
    this.getTasksOrder({ id: this.orderId });
  }
};
</script>

<style lang="scss">
.order-col {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  column-gap: 20px;
}
.order-field {
  max-width: 48%;
  width: 100%;
  input {
    color: black !important;
  }
  label {
    font-weight: 600;
    color: #676767 !important;
  }
}
.order-textarea textarea {
  color: black !important;
}
@media (max-width: 900px) {
  .order-col {
    max-height: inherit;
  }
  .order-field {
    max-width: 100%;
  }
}
.order-field:nth-child(11) {
  background-color: red !important;
  display: none;
}
</style>
