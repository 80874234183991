var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-row',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"transparent","small":"","elevation":"0"},on:{"click":_vm.backOrderArhive}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("buttons.back"))+" ")],1),_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('h2',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("new.order"))+" № "+_vm._s(_vm.orderId))])])],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"lg":"4","md":"12"}},[_c('v-btn',{staticClass:"btn__task",class:{ active: _vm.activeButton === 'order' },attrs:{"width":"100%"},on:{"click":_vm.buttonOrder}},[_vm._v(_vm._s(_vm.$t("buttons.orderCard")))])],1),_c('v-col',{attrs:{"lg":"4","md":"12"}},[_c('v-btn',{staticClass:"btn__task",class:{ active: _vm.activeButton === 'task' },attrs:{"width":"100%"},on:{"click":_vm.buttonTask}},[_vm._v(_vm._s(_vm.$t("buttons.taskTransportation")))])],1)],1),(_vm.orderCard)?_c('v-row',[_c('v-col',{attrs:{"lg":"8","md":"12"}},[_c('div',{staticClass:"order-col"},_vm._l((_vm.ORDERFIELDS),function(header){return _c('v-text-field',{key:header.value,staticClass:"order-field pa-0",attrs:{"label":header.text,"value":_vm.values(header.value),"disabled":""}})}),1),_c('v-textarea',{staticClass:"order-textarea",attrs:{"value":_vm.ORDER.notation,"background-color":"#F1F3F9","rows":"3","no-resize":"","filled":"","disabled":""}})],1)],1):_vm._e(),(_vm.task)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-viewing',{attrs:{"headers":_vm.headers,"users_data":_vm.TASKSORDER,"counts":_vm.COUNT,"hide":true,"page":_vm.page,"load":_vm.load},on:{"onPagination":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(
            void 0, [ _vm.objectProfile,
            _vm.objectVehicle,
            _vm.sortOptions ].concat( argsArray )
          )},"sortBy":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile, _vm.objectVehicle ].concat( argsArray, [_vm.page] ))}}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }